import { render, staticRenderFns } from "./Menu.vue?vue&type=template&id=0b15b83a&scoped=true&"
import script from "./Menu.vue?vue&type=script&lang=ts&"
export * from "./Menu.vue?vue&type=script&lang=ts&"
import style0 from "./Menu.vue?vue&type=style&index=0&id=0b15b83a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b15b83a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListTile } from 'vuetify/lib/components/VList';
import { VListTileAction } from 'vuetify/lib/components/VList';
import { VListTileContent } from 'vuetify/lib/components/VList';
import { VListTileTitle } from 'vuetify/lib/components/VList';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VFooter,VIcon,VLayout,VList,VListGroup,VListTile,VListTileAction,VListTileContent,VListTileTitle,VSubheader})
