import Measurement from '@/models/Measurement/Measurement';
import { orderBy, pullAllBy, uniqBy } from 'lodash';
import { GetterTree } from 'vuex';
import { State } from './state';

const all = (state: State) => (): Measurement[] | null => {
  return pullAllBy(orderBy(uniqBy(state.measurements, 'title'), ['title'], ['asc']), [{ title: '' }], 'title');
};
export default {
  all
} as GetterTree<State, any>;
