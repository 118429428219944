<template>
  <v-toolbar flat height="60px" class="internal-toolbar">
    <label
      aria-hidden="true"
      class="v-label v-label--active theme--dark"
      style="left: 36px; right: auto; top:4px; position: absolute;font-size:12px;"
      >Current Project</label
    >
    <v-overflow-btn
      background-color="transparent"
      :items="orderedTeamProjects"
      :value="getSelectedProjectId"
      item-text="projectName"
      item-value="id"
      hide-details
      @change="handleChangeProject"
      class="projectSelector pa-0 "
      single-line
      fluid
    ></v-overflow-btn>
  </v-toolbar>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { orderBy } from 'lodash';
import {
    log
} from '@/helpers/ConsoleLogHelper';

export default {
  name: 'ProjectSelector',
  mounted() {
    this.getProjects();
  },
  computed: {
    ...mapState({
      userSettings: (state) => state.userProfile.settings,
      teamProjects: (state) => state.projects.teamProjects
    }),
    ...mapGetters({
      getLatestDocumentByProjectId: 'documents/latestByProjectId'
    }),
    orderedTeamProjects() {
      return orderBy(this.teamProjects, ['sync', 'projectName'], ['desc', 'asc']);
  },
      getSelectedProjectId(){
          return parseInt(this.userSettings.selectedProjectId);
      }
  },
  methods: {
    ...mapActions({
      executeFullServerSync: 'historyItems/executeFullServerSync',
      setUserProjectId: 'userProfile/setUserProjectId',
      getProjects: 'projects/getAll'
    }),

    handleChangeProject(projectId) {
      document.activeElement.blur();
      this.setUserProjectId(""+projectId);
      this.$router.push({ name: 'Documents', params: { "projectId": ""+projectId, "scrollToDocumentId": ""} }).catch((err) => {});
      this.$vuetify.goTo(0, {
        container: '#scroll-target',
        duration: 500,
        easing: 'easeInOutCubic',
        offset: 200
      });      
    }
  }
};
</script>

<style scoped>
.internal-toolbar >>> .v-toolbar__content {
  padding-right: 0px;
}
.projectSelector >>> .v-select__selections {
  display: inline-block;
  width: 100%;
}

.projectSelector >>> .v-select__selection {
  padding-top: 40px;
}
</style>
