<template>
  <span class="headline">{{ formTitle }}</span>
</template>

<script>
export default {
  name: 'FormTitle',
  computed: {
    formTitle() {
      const formName = this.$route.meta.title;
      return formName;
    }
  }
};
</script>

<style></style>
