import { GetterTree } from 'vuex';
import { State } from './state';

// Min amount of time to wait before testing connectivity again
const MIN_REFRESH_INTERVAL = 20 * 1000; // 20 seconds

const isReady = (state: State) => (): boolean => {
  const timenow = new Date().getTime();
  if (state.isOnline || (!state.isOnline && timenow - MIN_REFRESH_INTERVAL > state.lastCheck)) {
    return true;
  }
  return false;
};

export default {
  isReady
} as GetterTree<State, any>;
