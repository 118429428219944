import localForageServices from '@/api/localForageService';
import { getProducts } from '@/api/Product/getProducts';
import Product from '@/models/Product/Product';
import StoreNames from '@/models/StoreNames';
import { ActionContext, ActionTree } from 'vuex';
import { State } from './state';
const storeName = StoreNames.products;

const getAll = async (store: ActionContext<State, any>, isForce: boolean) => {
  if (store.state.isNewData || isForce) {
    const storage = await localForageServices.getStore(storeName);
    const data = await storage.getItems();
    const products = Object.entries(data).map((keyValue) => {
      return keyValue[1] as Product;
    });
    store.commit('SET_ALL', products);
    store.commit('NOTIFY_NEWDATA', false);
  }
  return;
};

const getFromServerByProjectId = async (store: ActionContext<State, any>, projectId: string) => {
  if (store.rootGetters['connectivity/isReady']()) {
    const state = store.rootState as any;
    const data = await getProducts(state.userProfile.userProfile.accessToken, process.env.VUE_APP_API_URL, projectId);
    if (!data) {
      return;
    }
    const { response, status } = data;
    const products: Product[] = [];
    response.forEach((p: any) => {
      products.push(Product.createFromModel(p) as Product);
    });
    return store.commit('SET_BYPROJECTID', { projectId, products });
  }
};

const notifyNewData = (store: ActionContext<State, any>, isNewData: boolean) => {
  store.commit('NOTIFY_NEWDATA', isNewData);
};

const setIsInSync = (store: ActionContext<State, any>, isinSync: boolean) => {
  store.commit('SET_ISINSYNC', isinSync);
};

export default {
  getAll,
  notifyNewData,
  setIsInSync,
  getFromServerByProjectId
} as ActionTree<State, any>;
