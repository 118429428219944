import { ActionContext, ActionTree } from 'vuex';
import { State } from './state';

const notifyNewData = (store: ActionContext<State, any>, isNewData: boolean) => {
  store.commit('NOTIFY_NEWDATA', isNewData);
};

const setIsInSync = (store: ActionContext<State, any>, isinSync: boolean) => {
  store.commit('SET_ISINSYNC', isinSync);
};

export default {
  notifyNewData,
  setIsInSync
} as ActionTree<State, any>;
