import ISupplier from './ISupplier';

export default class Supplier {
  public static createFromModel(supplier: ISupplier) {
    return new Supplier(supplier.default, supplier.title, supplier.value);
  }

  public readonly default: string;
  public readonly title: string;
  public readonly value: string;

  public constructor(def: string, title: string, value: string) {
    this.default = def;
    this.title = title;
    this.value = (typeof value === 'number')?Number(value).toString():value;
  }
}
