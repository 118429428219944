import { mapModelToObject } from '@/models/Document/DocumentMapper';
import IDocumentVersionModel from '@/models/Document/IDocumentVersionModel';
import Document from './Document';
export default class DocumentVersion {
  public static createFromModel(version: IDocumentVersionModel) {
    if (!version.timestamp || !version.data) {
      throw new Error('Malformed DocumentVersion');
    }

    return new DocumentVersion(version.timestamp * 1000, mapModelToObject(version.data));
  }

  public readonly timestamp: number;
  public readonly document: Document;

  public constructor(timestamp: number, document: Document) {
    this.timestamp = timestamp;
    this.document = document;
  }
}
