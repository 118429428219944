import Picture from '@/models/Picture/Picture';
import Vue from 'vue';
import { MutationTree } from 'vuex';
import { State } from './state';

function SET_ALL(state: State, pictures: Picture[]) {
  Vue.set(state, 'pictures', pictures);
}

function ADD(state: State, picture: Picture) {
  if (picture) {
    state.pictures.unshift(picture);
  }
}

function EDIT(state: State, picture: Picture) {
  if (picture) {
    state.pictures = [...state.pictures.filter((p: Picture) => p.id !== picture.id), picture];
  }
}

function DELETE(state: State, id: string) {
  const index = state.pictures.findIndex((p: Picture) => p.id === id);
  state.pictures.splice(index, 1);
}

function NOTIFY_NEWDATA(state: State, isNewData: boolean) {
  Vue.set(state, 'isNewData', isNewData);
}

function SET_ISINSYNC(state: State, isInSync: boolean) {
  Vue.set(state, 'isInSync', isInSync);
}

export default {
  SET_ALL,
  ADD,
  EDIT,
  DELETE,
  NOTIFY_NEWDATA,
  SET_ISINSYNC
} as MutationTree<State>;
