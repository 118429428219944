import store from '@/store/store';
import Document from './Document';

const createDraft = (document: Document, newId: string): Document => {
  const currentDate = Date.now();
  const userProfile = (store as any).state.userProfile.userProfile;
  const draft = {
    ...document,
    id: newId,
    masterId: document.id,
    createdBy: userProfile.id,
    userName: `${userProfile.firstName} ${userProfile.lastName}`,
    date: currentDate
  };

  return draft;
};

const createDocument = (newId: string): Document => {
  const userSettings = (store as any).state.userProfile.settings;
  const userProfile = (store as any).state.userProfile.userProfile;

  const projectId = userSettings.selectedProjectId;
  const userName = `${userProfile.firstName} ${userProfile.lastName}`;
  const createdBy = userProfile.id;
  const document = new Document(newId, projectId, createdBy, userName, newId);
  document.isNew = true;
  return document;
};

export default {
  createDraft,
  createDocument
};
