import IProduct from './IProduct';

export default class Product {
  public static createFromModel(product: IProduct) {
    return new Product(product.default, product.title, product.value);
  }

  public readonly default: string;
  public readonly title: string;
  public readonly value: string;

  public constructor(def: string, title: string, value: string) {
    this.default = def;
    this.title = title;
    this.value = (typeof value === 'number')?Number(value).toString():value;
  }
}
