import ITeamProject from '@/models/Project/ITeamProject';

export default class TeamProject {
  public static createFromModel(teamProjectModel: ITeamProject) {
    return new TeamProject(
      teamProjectModel.id,
      teamProjectModel.projectName,
      teamProjectModel.sync,
      teamProjectModel.approver,
      teamProjectModel.manager,
      teamProjectModel.regularuser
    );
  }

  public readonly id: string;
  public readonly projectName: string;
  public readonly sync: boolean;
  public readonly isApprover: boolean;
  public readonly isManager: boolean;
  public readonly isRegularUser: boolean;

  public constructor(
    id: string,
    projectName: string,
    sync: boolean,
    isApprover: boolean,
    isManager: boolean,
    isRegularUser: boolean
  ) {
    if (!id) {
      throw new Error('Malformed object');
    }
    this.id = id;
    this.projectName = projectName;
    this.sync = sync;
    this.isApprover = isApprover;
    this.isManager = isManager;
    this.isRegularUser = isRegularUser;
  }
}
