export default class HistoryItem {
  public readonly id: string;
  public readonly storeName: string;
  public readonly keyPath: string;
  public readonly key: string;
  public readonly action: string;
  public readonly fields: {};
  public readonly timestamp: number;

  public constructor(
    id: string,
    storeName: string,
    keyPath: string,
    key: string,
    action: string,
    fields = {},
    timestamp: number = 0
  ) {
    this.id = id;
    this.storeName = storeName;
    this.keyPath = keyPath;
    this.key = key;
    this.action = action;
    this.fields = fields;
    this.timestamp = timestamp;
  }
}
