import Project from '@/models/Project/Project';
import TeamProject from '@/models/Project/TeamProject';
import Vue from 'vue';
import { MutationTree } from 'vuex';
import { State } from './state';

const SET_ALL = (state: State, projects: Project[]) => {
  Vue.set(state, 'projects', projects);
};
const SET_TEAM_PROJECTS = (state: State, teamProjects: TeamProject[]) => {
  Vue.set(state, 'teamProjects', teamProjects);
};

const NOTIFY_NEWDATA = (state: State, isNewData: boolean) => {
  Vue.set(state, 'isNewData', isNewData);
};

const SET_ISINSYNC = (state: State, isInSync: boolean) => {
  Vue.set(state, 'isInSync', isInSync);
};
export default {
  SET_ALL,
  SET_TEAM_PROJECTS,
  NOTIFY_NEWDATA,
  SET_ISINSYNC
} as MutationTree<State>;
