import axios from 'axios';
import { handleError } from './APIErrorHandler';
import { handleSuccess } from './APISuccessHandler';

export const login = async (request: any, apiURL: string) => {
  try {
    const response = await axios.post(apiURL + 'login', JSON.stringify(request));
    return handleSuccess(response);
  } catch (error) {
    handleError(error);
  }
};
