import axios from 'axios';
import { handleError } from '../APIErrorHandler';
import { handleSuccess } from '../APISuccessHandler';
export const getProducts = async (accessToken: string, apiURL: string, projectId?: string) => {
  const url = `${apiURL}products?project=${projectId ? projectId : ''}`;
  try {
    const response = await axios.get(url, {
      headers: { 'Access-Token': accessToken }
    });
    return handleSuccess(response);
  } catch (error) {
    handleError(error);
  }
};
