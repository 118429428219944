import Picture from '@/models/Picture/Picture';
import PictureSizes from '@/models/Picture/PictureSizes';
import { GetterTree } from 'vuex';
import { State } from './state';
const srcById = (state: State) => (id: string) => {
  const picture = state.pictures.find((p: Picture) => p.id === id);
  const imgSrc = picture ? picture.imgSrc : '';
  return imgSrc !== '' ? imgSrc : `${process.env.BASE_URL}img/no-photo.png`;
};

const srcByIdAndSize = (state: State) => (id: string, size: PictureSizes) => {
  const picture = state.pictures.find((p: Picture) => p.id === id);
  let imgSrc = '';
  if (picture) {
    switch (size) {
      case PictureSizes.ENLARGED:
        imgSrc = picture.imgEnlargedSize;
        break;
      case PictureSizes.LARGE:
        imgSrc = picture.imgLargeSize;
        break;
      case PictureSizes.MEDIUM:
        imgSrc = picture.imgMediumSize;
        break;
      case PictureSizes.SMALL:
        imgSrc = picture.imgSmallSize;
        break;
      case PictureSizes.TINY:
        imgSrc = picture.imgTinySize;
        break;
      default:
        break;
    }
    imgSrc = imgSrc ? imgSrc : picture.imgSrc; //return initial picture if size not available.
  }

  return imgSrc !== '' ? imgSrc : `${process.env.BASE_URL}img/no-photo.png`;
};

const byId = (state: State) => (id: string) => {
  return state.pictures.find((p: Picture) => p.id === id);
};

const byDocumentId = (state: State, getters: any, rootState: any, rootGetters: any) => (
  documentId: string
): Picture[] => {
  const document = rootGetters['documents/byId'](documentId);
  if (!document) {
    return [];
  }
  return state.pictures.filter((p: Picture) => document.pictureIds.indexOf(p.id) > -1);
};

const byDocumentVersion = (state: State, getters: any, rootState: any, rootGetters: any) => (
  documentId: string,
  timestamp: number
): Picture[] => {
  const document = rootGetters['documents/version'](documentId, timestamp);
  if (!document) {
    return [];
  }
  return state.pictures.filter((p: Picture) => document.pictureIds.indexOf(p.id) > -1);
};

export default {
  srcById,
  byId,
  byDocumentId,
  byDocumentVersion,
  srcByIdAndSize
} as GetterTree<State, any>;
