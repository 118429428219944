import HistoryItemFactory from '@/api/HistoryItemFactory';
import localForageServices from '@/api/localForageService';
import Picture from '@/models/Picture/Picture';
import StoreNames from '@/models/StoreNames';
import { isEmpty } from 'lodash';
import { ActionContext, ActionTree } from 'vuex';
import { State } from './state';
const storeName = StoreNames.pictures;

const getAll = async (store: ActionContext<State, any>, isForce: true) => {
  if (store.state.isNewData || isForce) {
    const storage = await localForageServices.getStore(storeName);
    const data = await storage.getItems();

    const pictures = Object.entries(data).map((keyValue) => {
      return keyValue[1] as Picture;
    });

    store.commit('SET_ALL', pictures);
    store.commit('NOTIFY_NEWDATA', false);
  }
  return;
};

const save = async (store: ActionContext<State, any>, picture: Picture) => {
  const storage = await localForageServices.getStore(storeName);
  const base = await storage.getItem(picture.id);

  store.commit('EDIT', picture);
  await storage.setItem(picture.id, picture);
  const historyItem = HistoryItemFactory.createHistoryItem(storeName, 'id', picture.id, picture, base);
  if (!isEmpty(historyItem.fields)) {
    delete (historyItem.fields as any)['imgEnlargedSize']; //Removes fields that should be only set on server.
    delete (historyItem.fields as any)['imgLargeSize'];
    delete (historyItem.fields as any)['imgMediumSize'];
    delete (historyItem.fields as any)['imgSmallSize'];
    delete (historyItem.fields as any)['imgTinySize'];
    return store.dispatch('historyItems/save', historyItem, { root: true });
  }
};

const add = async (store: ActionContext<State, any>, payload: { document: Document; picture: Picture }) => {
  const { document, picture } = payload;
  store.commit('ADD', picture);
  store.commit('documents/ADD_PICTURE', payload, { root: true });
};

const remove = async (store: ActionContext<State, any>, payload: { document: Document; picture: Picture }) => {
  const { document, picture } = payload;
  //store.commit('DELETE', picture.id); Do no remove picture from vuex store at it might be used in other version.
  store.commit('documents/REMOVE_PICTURE', payload, { root: true });
};

const notifyNewData = async (store: ActionContext<State, any>, isNewData: boolean) => {
  store.commit('NOTIFY_NEWDATA', isNewData);
};

const setIsInSync = async (store: ActionContext<State, any>, isinSync: boolean) => {
  store.commit('SET_ISINSYNC', isinSync);
};

export default {
  getAll,
  save,
  remove,
  add,
  notifyNewData,
  setIsInSync
} as ActionTree<State, any>;
