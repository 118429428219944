import Document from '@/models/Document/Document';
import ChartOfAccount from '@/models/ChartOfAccount/ChartOfAccount';
import { orderBy, pullAllBy, uniqBy } from 'lodash';
import { GetterTree } from 'vuex';
import { State } from './state';

const all = (state: State) => (): ChartOfAccount[] | null => {
  return pullAllBy(orderBy(uniqBy(state.chartOfAccounts, 'code'), ['name'], ['asc']), [{ title: '' }], 'title');
};
const byProjectId = (state: State, getters: any, rootState: any, rootGetters: any) => (projectId: string): ChartOfAccount[] | null => {
  const recentItems = 1;
  const documents = rootGetters['documents/byProjectId'](projectId);
  const sortedDocs = orderBy(uniqBy(documents, 'chartOfAccount'), 'date', 'desc').slice(0,recentItems-1);
  let newestCoasStrings = sortedDocs.map((obj:any) => obj.chartOfAccount);
  const coas = state.chartOfAccounts.filter((c: ChartOfAccount) => ''+c.project === ''+projectId);
  let sortedCoas = pullAllBy(orderBy(uniqBy(coas, 'title'), ['code','name'], ['asc']), [{ title: '' }], 'title');
  for (var i:number=recentItems-1; i >= 0; i--) {
    sortedCoas.unshift(sortedCoas.splice(sortedCoas.findIndex(elt => elt.title === newestCoasStrings[i]),1)[0])
  }
  return sortedCoas;
};
export default {
  all,
  byProjectId
} as GetterTree<State, any>;
