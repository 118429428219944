import IChartOfAccount from './IChartOfAccount';

export default class ChartOfAccount {
  public static createFromModel(coa: IChartOfAccount) {
    return new ChartOfAccount(coa.code, coa.name, coa.project, coa.title);
  }
  public readonly code: string;
  public readonly name: string;
  public readonly project: string;
  public readonly title: string;

  public constructor(code: string, name: string, project: string, title: string) {
    this.code = code;
    this.name = name;
    this.project = project;
    this.title = title;
  }
}
