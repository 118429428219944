import 'material-design-icons-iconfont/dist/material-design-icons.css'; // Ensure you are using css-loader
import Vue from 'vue';
// Helpers
import colors from 'vuetify/es5/util/colors';
import Vuetify from 'vuetify/lib';
import 'vuetify/src/stylus/app.styl';

Vue.use(Vuetify, {
  iconfont: 'md',
  options: {
    customProperties: true
  },
  theme: {
    primary: { base: colors.lightGreen.darken2 }, // #689F38
    secondary: colors.lightGreen.lighten2, // #AED581
    accent: colors.lightGreen.base // #8BC34A
  }
});
