import localForageServices from '@/api/localForageService';
import { getSuppliers } from '@/api/Supplier/getSuppliers';
import StoreNames from '@/models/StoreNames';
import Supplier from '@/models/Supplier/Supplier';
import { ActionContext, ActionTree } from 'vuex';
import { State } from './state';
const storeName = StoreNames.suppliers;

const getAll = async (store: ActionContext<State, any>, isForce: boolean) => {
  if (store.state.isNewData || isForce) {
    const storage = await localForageServices.getStore(storeName);

    const data = await storage.getItems();
    const suppliers = Object.entries(data).map((keyValue) => {
      return keyValue[1] as Supplier;
    });
    store.commit('SET_ALL', suppliers);
    store.commit('NOTIFY_NEWDATA', false);
  }
  return;
};

const getFromServerByProjectId = async (store: ActionContext<State, any>, projectId: string) => {
  if (store.rootGetters['connectivity/isReady']()) {
    const state = store.rootState as any;
    const data = await getSuppliers(state.userProfile.userProfile.accessToken, process.env.VUE_APP_API_URL, projectId);
    if (!data) {
      return;
    }
    const { response, status } = data;
    const suppliers: Supplier[] = [];
    response.forEach((p: any) => {
      suppliers.push(Supplier.createFromModel(p) as Supplier);
    });
    return store.commit('SET_BYPROJECTID', { projectId, suppliers });
  }
};

const notifyNewData = (store: ActionContext<State, any>, isNewData: boolean) => {
  store.commit('NOTIFY_NEWDATA', isNewData);
};

const setIsInSync = (store: ActionContext<State, any>, isinSync: boolean) => {
  store.commit('SET_ISINSYNC', isinSync);
};

export default {
  getAll,
  notifyNewData,
  setIsInSync,
  getFromServerByProjectId
} as ActionTree<State, any>;
