









import Vue from 'vue';
import { mapState, mapActions, mapGetters } from 'vuex';
export default Vue.extend({
  name: 'App',
  computed: {
    ...mapState({
      isOnline: (state: any) => state.connectivity.isOnline,
      errorMessage: (state: any) => state.connectivity.errorMessage,
      lastCheck: (state: any) => state.connectivity.lastCheck
    }),
    isShowIcon() {
      return !this.isOnline || this.errorMessage;
    },
    icon() {
      if (!this.isOnline) {
        return 'wifi_off';
      } else if (this.errorMessage) {
        return 'warning';
      }
      return '';
    },
    tooltip() {
      if (!this.isOnline) {
        return `Last check on ${new Date(this.lastCheck).toLocaleString()}`;
      } else if (this.errorMessage) {
        return this.errorMessage;
      }
      return '';
    }
  }
});
