import localforage from 'localforage';
import localforageGetItems from 'localforage-getitems';
import { log } from '@/helpers/ConsoleLogHelper';

const stores = new Map();
const getitems = localforageGetItems; // do not delete, needed to get localForageGetItems function attacehd.

const getStore = async (storeName: string): Promise<LocalForage> => {
  const foundStore = stores.get(storeName);
  if (foundStore) {
    return foundStore;
  } else {
    return await createStore(storeName);
  }
};

const createStore = async (storeName: string) => {
  log(`Creating store ${storeName}`);
  const createdStore = localforage.createInstance({
    name: 'SnapperMQ',
    version: 1.0,
    storeName
  });
  await createdStore.setDriver([localforage.INDEXEDDB, localforage.WEBSQL, localforage.LOCALSTORAGE]);

  stores.set(storeName, createdStore);
  log(`Finished creating store ${storeName}`);
  return createdStore;
};
export default {
  getStore
};
