













































































import Vue from 'vue';
import { mapState, mapActions, mapGetters } from 'vuex';
import { log } from '@/helpers/ConsoleLogHelper';
import { deleteDB } from '@/helpers/DeleteDBHelper';
export default Vue.extend({
  data: () => ({
    items: [
      {
        action: 'folder',
        active: false,
        group: 'user',
        title: 'Documents',
        route: 'Documents'
      },
      {
        action: 'account_circle',
        title: 'Profile',
        group: 'user',
        active: false,
        route: 'user/profile'
      },
      {
        action: 'exit_to_app',
        title: 'Log Out',
        group: 'user',
        active: false,
        route: 'user/logout'
      }
    ]
  }),
  props: ['value'],
  computed: {
    ...mapState({
      userProfile: (state: any) => state.userProfile.userProfile,
      isOnline: (state: any) => state.connectivity.isOnline
    }),
    ...mapGetters({
      isConnectivityReady: 'connectivity/isReady'
    }),
    logoLarge() {
      return `${process.env.BASE_URL}img/logo-large-mq.png`;
    },
    version() {
      return `${process.env.VERSION}`;
    }
  },
  methods: {
    ...mapActions({
      executeFullServerSync: 'historyItems/executeFullServerSync',
      pushAllToServer: 'historyItems/pushAllToServer',
      getAllDocuments: 'documents/getAll',
      getAllPictures: 'pictures/getAll',
      getAllProducts: 'products/getAll',
      getAllSuppliers: 'suppliers/getAll',
      getAllMeasurements: 'measurements/getAll',
      getAllCoas: 'coas/getAll'
    }),

    isShowToUserGroup(requiredGroup: string) {
      if (requiredGroup === 'user') {
        return true;
      } else if (requiredGroup === 'admin') {
        return this.userProfile.group === 'admin' ? true : false;
      }
    },

    async handleFullSync() {
      await this.pushAllToServer();
      if (this.isConnectivityReady()) {
        await deleteDB();
        log('All data cleared');

        if (navigator.serviceWorker) {
          navigator.serviceWorker.getRegistrations().then(async (registrations) => {
            for (const registration of registrations) {
              await registration.unregister();
            }
          });
        }
      }
      localStorage.setItem('isFullSync', 'true');
      this.$emit('handleCloseDrawer');
      log('App will now refresh');
      this.$router.replace({ name: 'Home' });
    },

    async handleForceSync() {
      await this.executeFullServerSync();
      this.$emit('handleCloseDrawer');
      await Promise.all([
        this.getAllDocuments(true),
        this.getAllPictures(true),
        this.getAllProducts(true),
        this.getAllMeasurements(true),
        this.getAllCoas(true),
        this.getAllSuppliers(true)
      ]);
    }
  }
});
