



















































import Vue from 'vue';
import ActivatorLabelStyles from '@/components/Common/FullScreenTooltip/ActivatorLabelStyles';
export default Vue.extend({
  name: 'FullScreenTooltip',
  data: () => ({
    isShown: false
  }),
  props: [
    'activatorIcon',
    'activatorLabel',
    'activatorLabelStyle',
    'transition',
    'backgroundColor',
    'headerBackgroundColor',
    'leftActionLabel',
    'leftActionIcon',
    'rightActionLabel',
    'rightActionIcon'
  ],
  computed: {
    width(): string {
      const widthRatio = 1;

      return (this.$vuetify as any).breakpoint.width * widthRatio + 'px';
    },
    height(): string {
      let adjust = 0;
      // let adjust = 56;
      // the toolbar is 64px high on desktop, 56px high on mobile portrait and 48px high on mobile
      // if ((this.$vuetify as any).breakpoint.name !== 'xs') {
      //   adjust = 64;
      // }
      return (this.$vuetify as any).breakpoint.height - adjust + 'px';
    },
    isActivatorLabelBold(): boolean {
      return this.activatorLabelStyle === ActivatorLabelStyles.bold ? true : false;
    },

    isActivatorLabelItalic(): boolean {
      return this.activatorLabelStyle === ActivatorLabelStyles.italic ? true : false;
    }
  },
  methods: {
    handleClick() {
      this.isShown = !this.isShown;
    },
    handleLeftActionClick(): void {
      this.isShown = false;
      this.$emit('handleLeftActionClick');
    },
    handleRightActionClick(): void {
      this.isShown = false;
      this.$emit('handleRightActionClick');
    }
  }
});
