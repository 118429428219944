import localForageService from '@/api/localForageService';
import StoreNames from '@/models/StoreNames';
import store from '@/store/store';

export const handleDeletedProjects = async (projectIdsNow: string[]) => {
  const documentStorage = await localForageService.getStore(StoreNames.documents);
  const pictureStorage = await localForageService.getStore(StoreNames.pictures);
  const projectStorage = await localForageService.getStore(StoreNames.projects);

  documentStorage.iterate((value: any, key: string) => {
    deleteIfNotInProjectIds(documentStorage, projectIdsNow, value, key);
  });

  pictureStorage.iterate((value: any, key: string) => {
    deleteIfNotInProjectIds(pictureStorage, projectIdsNow, value, key);
  });

  projectStorage.iterate((value: any, key: string) => {
    if (projectIdsNow.length > 0) {
      if (value.id && projectIdsNow.indexOf(value.id) === -1) {
        projectStorage.removeItem(key);
      }
    } else {
      projectStorage.removeItem(key);
    }
  });

  const selectedProjectId = (store as any).state.userProfile.settings.selectedProjectId;

  if (selectedProjectId && projectIdsNow.indexOf(selectedProjectId) === -1) {
    return store.dispatch('userProfile/setUserProjectId', undefined);
  }
};

const deleteIfNotInProjectIds = (storage: LocalForage, projectIds: string[], value: any, key: string) => {
  if (projectIds.length > 0) {
    if (value.projectId && projectIds.indexOf(value.projectId) === -1) {
      storage.removeItem(key);
    }
  } else {
    storage.removeItem(key);
  }
};
