import Vue from 'vue';
import { MutationTree } from 'vuex';
import { State } from './state';

const SET_IS_ONLINE = (state: State, isOnline: boolean) => {
  Vue.set(state, 'isOnline', isOnline);
  Vue.set(state, 'errorMessage', '');
  Vue.set(state, 'lastCheck', new Date().getTime());
};

const SET_ERROR = (state: State, errorMessage: boolean) => {
  Vue.set(state, 'errorMessage', errorMessage);
  Vue.set(state, 'lastCheck', new Date().getTime());
};

export default {
  SET_IS_ONLINE,
  SET_ERROR
} as MutationTree<State>;
