import Measurement from '@/models/Measurement/Measurement';
import Vue from 'vue';
import { MutationTree } from 'vuex';
import { State } from './state';

const SET_ALL = (state: State, measurements: Measurement[]) => {
  Vue.set(state, 'measurements', measurements);
};

const NOTIFY_NEWDATA = (state: State, isNewData: boolean) => {
  Vue.set(state, 'isNewData', isNewData);
};

const SET_ISINSYNC = (state: State, isInSync: boolean) => {
  Vue.set(state, 'isInSync', isInSync);
};

export default {
  SET_ALL,
  NOTIFY_NEWDATA,
  SET_ISINSYNC
} as MutationTree<State>;
