import { ChartOfAccounts } from '@/store/modules/ChartOfAccount';
import { Connectivity } from '@/store/modules/Connectivity';
import { Documents } from '@/store/modules/Document';
import { DocumentDrafts } from '@/store/modules/DocumentDrafts';
import { HistoryItems } from '@/store/modules/HistoryItem';
import { Measurements } from '@/store/modules/Measurement';
import { Pictures } from '@/store/modules/Pictures';
import { Products } from '@/store/modules/Product';
import { Projects } from '@/store/modules/Project';
import { Suppliers } from '@/store/modules/Supplier';
import { UserProfile } from '@/store/modules/UserProfile';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    initialized: false
  },
  actions: {
    /*Global*/
  },
  mutations: {
    /*Global*/
  },
  modules: {
    connectivity: new Connectivity(),
    historyItems: new HistoryItems(),
    userProfile: new UserProfile(),
    projects: new Projects(),
    documents: new Documents(),
    pictures: new Pictures(),
    products: new Products(),
    measurements: new Measurements(),
    coas: new ChartOfAccounts(),
    suppliers: new Suppliers(),
    documentDrafts: new DocumentDrafts()
  }
});
