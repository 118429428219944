import store from '@/store/store';
import Bowser from 'bowser';
import moment from 'moment';
import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import(/* webpackChunkName: "home" */ './views/Home.vue'),
      meta: {
        title: 'Home',
        requiresAuth: true
      }
    },
    /* Add to home Screen iPhone modal */
    {
      path: '/add-to-homescreen',
      name: 'addToHomeScreen',
      component: () => import(/* webpackChunkName: "addToHomeScreen" */ './views/AddToHomeScreen.vue')
    },

    /*Documents*/
    {
      path: '/documents/:scrollToDocumentId?',
      name: 'Documents',
      component: () => import(/* webpackChunkName: "documents" */ './views/Documents.vue'),
      props: true,
      meta: {
        title: 'Documents',
        level: 0,
        requiresAuth: true
      }
    },
    {
      path: '/documents/edit/:documentId?&:timestamp?',
      name: 'Document',
      component: () => import(/* webpackChunkName: "document" */ './components/Document/Form/DocumentTabs.vue'),
      props: true,
      meta: {
        title: 'Transaction',
        level: 1,
        requiresAuth: true
      }
    },
    /*Pictures*/
    {
      path: '/picture/:pictureId',
      name: 'Picture',
      component: () => import(/* webpackChunkName: "picture" */ './views/Picture.vue'),
      props: true,
      meta: {
        title: 'Picture',
        level: 1,
        requiresAuth: true
      }
    },
    {
      path: '/user/login',
      name: 'Login',
      component: () => import(/* webpackChunkName: "login" */ './views/Login.vue'),
      props: true,
      meta: {
        title: 'Login',
        level: 1,
        requiresAuth: false
      }
    },
    {
      path: '/user/logout',
      name: 'Logout',
      meta: {
        title: 'Login',
        requiresAuth: true
      }
    },
    {
      path: '/user/profile',
      name: 'Profile',
      component: () => import(/* webpackChunkName: "about" */ './views/Profile.vue'),
      meta: {
        title: 'Profile',
        level: 0,
        requiresAuth: true
      }
    }
  ]
});

router.beforeEach((to, from, next) => {
  if (to.name === 'Logout') {
    store.dispatch('userProfile/logout', { root: true });
  }
  if (to && to.meta && to.meta.title) {
    document.title = `${to.meta.title} `;
  } else {
    document.title = process.env.VUE_APP_NAME;
  }

  if (from.name === 'Document' && to.name === 'Documents') {
    to.params.scrollToDocumentId = from.params.documentId;
  }
  next();
});

router.beforeResolve(async (to, from, next) => {
  const state = store.state as any;
  const isAuthed = state.userProfile.isAuthed;
  const userProfile = state.userProfile.userProfile;
  if (to.matched.some((record) => record.meta.requiresAuth) && !isAuthed) {
    next({
      name: 'Login'
    });
  } else if (to.matched.some((record) => record.meta.requiresAdmin) && userProfile.group !== 'admin') {
  } else {
    if (to.name === 'addToHomeScreen') {
      next();
    }
    const standalone = to.fullPath.includes('?standalone');
    if (!needsToSeePrompt(standalone)) {
      next();
    } else {
      next({
        name: 'addToHomeScreen'
      });
    }
    next();
  }
});

function needsToSeePrompt(standalone: boolean) {
  // we pass in the result of our query-param to this method
  let navigatorVar: any;
  navigatorVar = window.navigator;
  if (navigatorVar.standalone || standalone) {
    return false;
  }

  const today = moment();
  let lastSeenPrompt = 0;
  if (localStorage.lastSeenPrompt) {
    lastSeenPrompt = localStorage.lastSeenPrompt;
  }
  const days = today.diff(lastSeenPrompt, 'days');
  const secondsForTest = today.diff(lastSeenPrompt, 'seconds');

  const isApple = ['iPhone', 'iPad', 'iPod'].includes(navigator.platform);
  const browser = Bowser.getParser(window.navigator.userAgent);
  const isSafari = browser.getBrowserName() === 'Safari' ? true : false;
  // return (isNaN(days) || days > 14) && isApple;
  return (isNaN(days) || days > 1) && isApple && isSafari;
}

export default router;
