import Document from '@/models/Document/Document';
import Project from '@/models/Project/Project';
import TeamProject from '@/models/Project/TeamProject';
import { orderBy,uniq } from 'lodash';
import { GetterTree } from 'vuex';
import { State } from './state';
import { log } from '@/helpers/ConsoleLogHelper';

const byId = (state: State) => (id: string): Project | undefined => {
  return state.projects.find((c) => c.id == id);
};
const nameById = (state: State) => (id: string): string => {
  const getById = byId(state);
  const project = getById(id);
  return project ? project.projectName : '';
};
const hasDocuments = (state: State, getters: any, rootState: any, rootGetters: any) => (): TeamProject[] => {
  const projectIds = uniq(rootState.documents.documents.map((d: Document) => parseInt(d.projectId)));
  const projects = state.teamProjects.filter((p) => p.sync && projectIds.includes(parseInt(p.id)));
  return orderBy(projects, 'projectName', 'asc');
};

const isRegularUser = (state: State) => (projectId: string) => {
  const teamProject = state.teamProjects.find((p: TeamProject) => p.id === projectId);
  if (teamProject) {
    return teamProject.isRegularUser;
  }
  return false;
};
const isManager = (state: State) => (projectId: string) => {
  const teamProject = state.teamProjects.find((p: TeamProject) => p.id === projectId);
  if (teamProject) {
    return teamProject.isManager;
  }
  return false;
};
const isApprover = (state: State) => (projectId: string) => {
  const teamProject = state.teamProjects.find((p: TeamProject) => p.id === projectId);
  if (teamProject) {
    return teamProject.isApprover;
  }
  return false;
};
export default {
  byId,
  nameById,
  hasDocuments,
  isRegularUser,
  isManager,
  isApprover
} as GetterTree<State, any>;
