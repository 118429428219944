import localForageServices from '@/api/localForageService';
import StoreNames from '@/models/StoreNames';
import { ActionContext, ActionTree } from 'vuex';
import { State } from './state';
const storeName = StoreNames.coas;

const getAll = async (store: ActionContext<State, any>, isForce: boolean) => {
  const storage = await localForageServices.getStore(storeName);
  if (store.state.isNewData || isForce) {
    const data = await storage.getItems();
    const coas = Object.entries(data).map((keyValue) => {
      return keyValue[1];
    });
    store.commit('SET_ALL', coas);
    store.commit('NOTIFY_NEWDATA', false);
  }
  return;
};

const notifyNewData = (store: ActionContext<State, any>, isNewData: boolean) => {
  store.commit('NOTIFY_NEWDATA', isNewData);
};

const setIsInSync = (store: ActionContext<State, any>, isinSync: boolean) => {
  store.commit('SET_ISINSYNC', isinSync);
};

export default {
  getAll,
  notifyNewData,
  setIsInSync
} as ActionTree<State, any>;
