import { log } from '@/helpers/ConsoleLogHelper';
import { register } from 'register-service-worker';
let isServiceWorkerRunning = false;

// // Message type
// interface IMessage {
//   command: string;
//   options: any;
// }
// export default IMessage;

/**
 * Sends a service worker message
 *
 * @param message An object containing a command value and options
 */
// export function postServiceWorkerMsg(message: IMessage) {
//   // Discard unvalid mssages upfront
//   if (!message || !message.command || !message.options) {
//     console.error('Malformed message, discarding.');
//     console.error(message);
//     return new Promise((resolve, reject) => {
//       reject('Malformed message, discarding.');
//     });
//   }

//   // Return a promise containing the reply from ServiceWorker
//   // Only if a service worker is present.
//   if (navigator.serviceWorker && navigator.serviceWorker.controller && isServiceWorkerRunning) {
//     return new Promise((resolve, reject) => {
//       const messageChannel = new MessageChannel();
//       messageChannel.port1.onmessage = (event) => {
//         if (event.data.error) {
//           reject(event.data.error);
//         } else {
//           resolve(event.data);
//         }
//       };

//       // This sends the message data as well as transferring messageChannel.port2 to the service worker.
//       // The service worker can then use the transferred port to reply via postMessage(), which
//       // will in turn trigger the onmessage handler on messageChannel.port1.
//       // See https://html.spec.whatwg.org/multipage/workers.html#dom-worker-postmessage
//       if (navigator.serviceWorker.controller) {
//         navigator.serviceWorker.controller.postMessage(message, [
//           messageChannel.port2
//         ]);
//       }
//     });
//   } else {
//     console.error('No service worker present, falling back to direct server calls....');
//     switch (message.command) {
//       // Sync all stores at once
//       case 'syncAll':
//         sync.syncEverything(message.options.minInterval);
//         break;
//       // Sync a specified store only
//       case 'syncStore':
//         sync.syncSingleStore(message.options.store, message.options.minInterval);
//         break;
//       // Push everything to server
//       case 'pushAll':
//         sync.pushToServer();
//         break;
//       default:
//         // NOOP
//         break;
//     }
//   }
// }

register(`${process.env.BASE_URL}service-worker.js`, {
  ready(swRegistration) {
    log('App is being served from cache by a service worker.\n' + 'For more details, visit https://goo.gl/AFskqB');
  },
  registered(registration) {
    log('Service worker has been registered.');
    isServiceWorkerRunning = true;

    // // Perform full server sync every 15 minutes
    // const fullSyncInterval = 1;
    // const minute = 60 * 1000;
    // setInterval(() => {
    //   postServiceWorkerMsg({
    //     command: 'syncAll',
    //     options: {
    //       // Skip if synced less than X seconds ago.
    //       minInterval: 0.25 * fullSyncInterval * 60
    //     }
    //   });
    // }, fullSyncInterval * (minute));

    // Check for service worker update every hour
    setInterval(() => {
      registration.update();
    }, 1000 * 60 * 60); // e.g. hourly checks
  },
  cached() {
    log('Content has been cached for offline use.');
  },
  updatefound() {
    log('New content is downloading.');
  },
  updated(registration) {
    log('New content is available; please refresh.');
    document.dispatchEvent(new CustomEvent('swUpdated', { detail: registration }));
  },
  offline() {
    log('No internet connection found. App is running in offline mode.');
  },
  error(error) {
    log(`Error during service worker registration: ${error}`);
    isServiceWorkerRunning = false;
  }
});
