import IUserProfile from './IUserProfile';

export default class UserProfile {
  public static createFromModel(userProfile: IUserProfile) {
    return new UserProfile(
      userProfile.id,
      userProfile.accesstoken,
      userProfile.fname,
      userProfile.lname,
      userProfile.access,
      userProfile.group,
      userProfile.cie,
      userProfile.email,
      userProfile.name,
      userProfile.role,
      userProfile.title,
      userProfile.mode
    );
  }

  public readonly accessToken: string;
  public readonly cie: string;
  public readonly email: string;
  public readonly firstName: string;
  public readonly group: string;
  public readonly id: string;
  public readonly lastName: string;
  public readonly name: string;
  public readonly role: string;
  public readonly title: string;
  public readonly access: string;
  public readonly mode: string;

  public constructor(
    id: string,
    accessToken: string,
    firstName: string,
    lastName: string,
    access: string,
    group?: string,
    cie?: string,
    email?: string,
    name?: string,
    role?: string,
    title?: string,
    mode?: string
  ) {
    this.id = id;
    this.accessToken = accessToken;
    this.firstName = firstName;
    this.lastName = lastName;
    this.access = access;
    this.cie = cie || '';
    this.email = email || '';
    this.group = group || '';
    this.name = name || '';
    this.role = role || '';
    this.title = title || '';
    this.mode = mode || 'MQ';
  }
}
