import HistoryItemFactory from '@/api/HistoryItemFactory';
import localForageServices from '@/api/localForageService';
import Document from '@/models/Document/Document';
import DocumentFactory from '@/models/Document/DocumentFactory';
import StoreNames from '@/models/StoreNames';
import { isEmpty } from 'lodash';
import nanoid from 'nanoid';
import { ActionContext, ActionTree } from 'vuex';
import { State } from './state';
const documentStoreName = StoreNames.documents;
const draftStoreName = StoreNames.documentDrafts;

const getAll = async (store: ActionContext<State, any>, isForce: boolean) => {
  if (store.state.isNewData || isForce) {
    const storage = await localForageServices.getStore(documentStoreName);
    const draftStorage = await localForageServices.getStore(draftStoreName);

    const documentData = await storage.getItems();
    const draftData = await draftStorage.getItems();

    const documents = Object.entries(documentData).map((keyValue) => {
      return keyValue[1] as Document;
    });

    const drafts = Object.entries(draftData).map((keyValue) => {
      return keyValue[1] as Document;
    });

    const documentsAndDrafts = [...documents, ...drafts];
    store.commit('SET_ALL', documentsAndDrafts);
    store.commit('NOTIFY_NEWDATA', false);
  }
  return;
};
const save = async (store: ActionContext<State, any>, payload: { document: Document; storeName?: string }) => {
  let { document, storeName } = payload;
  if (!storeName) {
    storeName = draftStoreName;
  }
  const storage = await localForageServices.getStore(storeName);

  let base = (await storage.getItem(document.id)) as Document;

  let historyItem = HistoryItemFactory.createHistoryItem(storeName, 'id', document.id, document, base);
  if (!isEmpty(historyItem.fields)) {
    store.commit('EDIT', document);
    await storage.setItem(document.id, document);

    delete (historyItem.fields as any)['history']; //Removes history field
    return store.dispatch('historyItems/save', historyItem, { root: true });
  }
};

const remove = async (store: ActionContext<State, any>, document: Document) => {
  if (!document.masterId) {
    removeById(store, { id: document.id, storeName: documentStoreName }); //Delete master
  } else if (document.masterId && document.masterId === document.id) {
    removeById(store, { id: document.id, storeName: draftStoreName }); //Delete draft, master does not exist.
  } else if (document.masterId && document.masterId !== document.id) {
    //delete draft and master.
    removeById(store, { id: document.id, storeName: draftStoreName });
  }
  getAll(store, true);
};

const removeById = async (store: ActionContext<State, any>, payload: { id: string; storeName: string }) => {
  const { id, storeName } = payload;

  store.commit('DELETE', id);
  const storage = await localForageServices.getStore(storeName);
  await storage.removeItem(id);
  const historyItem = HistoryItemFactory.createHistoryItem(storeName, 'id', id);
  return store.dispatch('historyItems/save', historyItem, { root: true });
};

const create = async (store: ActionContext<State, any>) => {
  const newId = nanoid();
  const document = DocumentFactory.createDocument(newId);
  store.commit('ADD', document);
  return newId;
};

const createDraft = async (store: ActionContext<State, any>, document: Document) => {
  const newId = nanoid();
  const draft = DocumentFactory.createDraft(document, newId);
  store.commit('ADD', draft);
  return newId;
};

const update = async (store: ActionContext<State, any>, payload: { document: Document; verified: boolean }) => {
  const { document, verified } = payload;

  const userProfile = store.rootState.userProfile.userProfile;
  const currentDate = Date.now();

  const updatedDocument = {
    ...document,
    createdBy: userProfile.id,
    userName: `${userProfile.firstName} ${userProfile.lastName}`,
    date: currentDate,
    verified: verified || false
  };
  store.commit('EDIT', updatedDocument);
};

const notifyNewData = (store: ActionContext<State, any>, isNewData: boolean) => {
  store.commit('NOTIFY_NEWDATA', isNewData);
};

const setIsInSync = (store: ActionContext<State, any>, isinSync: boolean) => {
  store.commit('SET_ISINSYNC', isinSync);
};

const toggleToSend = (store: ActionContext<State, any>, document: Document) => {
  document = { ...document, toSend: !document.toSend };
  store.commit('EDIT', document);
};

export default {
  getAll,
  save,
  delete: remove,
  removeById,
  createDraft,
  create,
  update,
  notifyNewData,
  setIsInSync,
  toggleToSend
} as ActionTree<State, any>;
