import axios from 'axios';
import { handleError } from '../APIErrorHandler';
import { handleSuccess } from '../APISuccessHandler';
export const getUserProfile = async (accessToken: string, apiURL: string) => {
  try {
    const response = await axios.get(apiURL + 'profile', {
      headers: { 'Access-Token': accessToken }
    });
    return handleSuccess(response);
  } catch (error) {
    handleError(error);
  }
};
