import { countBy } from 'lodash';
import { GetterTree } from 'vuex';
import { State } from './state';
const isAuthed = (state: State) => () => {
  return state.isAuthed;
};

const filterCount = (state: State) => () => {
  let count = 0;
  const results = countBy(state.settings.filters, 'isApplied');
  return results.true;
};

export default {
  isAuthed,
  filterCount
} as GetterTree<State, any>;
