// source : https://gist.github.com/Yimiprod/7ee176597fef230d1451

import { isArray, isEqual, isObject, transform } from 'lodash';

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export const difference = (object: any, base: any, skipProperty: string = 'key'): any => {
  const isHandleArray = false;
  //  const skipProperty = 'key';
  let arrayIndexCounter = 0;
  return transform(object, (result: any, value: any, key: any) => {
    if (!isEqual(value, base[key]) && key !== skipProperty) {
      let isBothObject = false;
      let isBothArray = false;

      const resultKey = isArray(base) && isHandleArray ? arrayIndexCounter++ : key;

      if (isArray(value) && isArray(base[key])) {
        isBothArray = true;
      } else if (
        isObject(value) &&
        isObject(base[key]) &&
        value instanceof Date === false &&
        base[key] instanceof Date === false
      ) {
        isBothObject = true;
      }
      result[resultKey] = (isBothArray && isHandleArray) || isBothObject ? difference(value, base[key]) : value;
    }
  });
};
