import DocumentFilter from '@/models/Document/DocumentFilter';
import DocumentLayouts from '@/models/DocumentLayouts';
import FilterNames from '@/models/FilterNames';
import IFilter from '@/models/IFilter';
import UserProfile from '@/models/User/UserProfile';
import UserProject from '@/models/User/UserProject';
import UserSettings from '@/models/User/UserSettings';
import { isEmpty } from 'lodash';
import Vue from 'vue';
import { MutationTree } from 'vuex';
import { getInitialState, State } from './state';

const SET_USER_SETTINGS = (state: State, settings: UserSettings) => {
  Vue.set(state, 'settings', settings);
};

const SET_USER_PROJECTS = (state: State, projects: UserProject[]) => {
  Vue.set(state, 'projects', projects);
};

const SET_ISAUTHED = (state: State, userProfile: UserProfile) => {
  if (userProfile) {
    state.userProfile = userProfile;
    state.isAuthed = true;
  } else {
    state.userProfile = undefined;
    state.isAuthed = false;
  }
};

const SET_SELECTEDFILTER = (state: State, selectedFilter: DocumentFilter) => {
  state.settings = { ...state.settings, selectedFilter };
};

const SET_USER_PROJECT = (state: State, selectedProjectId: string) => {
  state.settings = { ...state.settings, selectedProjectId };
};

const SET_QUERYTEXT = (state: State, queryText: string) => {
  state.settings = { ...state.settings, queryText };
};

const SET_DATEFROM = (state: State, dateFrom: number) => {
  const filter = state.settings.filters.find((f: IFilter) => f.name === FilterNames.DATE);
  filter!.parameters = { ...filter!.parameters, dateFrom };
};

const SET_DATETO = (state: State, dateTo: number) => {
  const filter = state.settings.filters.find((f: IFilter) => f.name === FilterNames.DATE);
  filter!.parameters = { ...filter!.parameters, dateTo };
};

const SET_AMOUNTFROM = (state: State, amountFrom: number) => {
  const filter = state.settings.filters.find((f: IFilter) => f.name === FilterNames.AMOUNT);
  filter!.parameters = { ...filter!.parameters, amountFrom };
};

const SET_AMOUNTTO = (state: State, amountTo: number) => {
  const filter = state.settings.filters.find((f: IFilter) => f.name === FilterNames.AMOUNT);
  filter!.parameters = { ...filter!.parameters, amountTo };
};

const SET_REDFLAG = (state: State, isRedFlag: boolean) => {
  const filter = state.settings.filters.find((f: IFilter) => f.name === FilterNames.FLAGS);
  filter!.parameters = { ...filter!.parameters, isRedFlag };
};
const SET_VERIFIED = (state: State, isVerified: boolean) => {
  const filter = state.settings.filters.find((f: IFilter) => f.name === FilterNames.FLAGS);
  filter!.parameters = { ...filter!.parameters, isVerified };
};
const SET_APPROVED = (state: State, isApproved: boolean) => {
  const filter = state.settings.filters.find((f: IFilter) => f.name === FilterNames.FLAGS);
  filter!.parameters = { ...filter!.parameters, isApproved };
};
const SET_ASC = (state: State, isAsc: boolean) => {
  const filter = state.settings.filters.find((f: IFilter) => f.name === FilterNames.FLAGS);
  filter!.parameters = { ...filter!.parameters, isAsc };
};

const CLEAR_FILTERS = (state: State) => {
  const initialState = getInitialState();
  const filters = initialState.settings.filters;
  state.settings = { ...state.settings, filters };
};
const APPLY_FILTERS = (state: State) => {
  state.settings.filters.forEach((f: IFilter) => {
    if (!isEmpty(f.parameters)) {
      f.isApplied = true;
    }
  });
};
const CANCEL_FILTERS = (state: State) => {
  state.settings.filters.forEach((f: IFilter) => {
    f.isApplied = false;
  });
};
const TOGGLE_DOCUMENT_PICTURE_PANEL = (state: State) => {
  state.settings = {
    ...state.settings,
    documentLayout:
      state.settings.documentLayout === DocumentLayouts.split ? DocumentLayouts.full : DocumentLayouts.split
  };
};

const CLOSE_DOCUMENT_PICTURE_PANEL = (state: State) => {
  state.settings = {
    ...state.settings,
    documentLayout: DocumentLayouts.full
  };
};

export default {
  SET_ISAUTHED,
  SET_USER_PROJECT,
  SET_USER_PROJECTS,
  SET_SELECTEDFILTER,
  SET_USER_SETTINGS,
  SET_QUERYTEXT,
  SET_DATEFROM,
  SET_DATETO,
  SET_AMOUNTFROM,
  SET_AMOUNTTO,
  CLEAR_FILTERS,
  APPLY_FILTERS,
  CANCEL_FILTERS,
  SET_REDFLAG,
  SET_VERIFIED,
  SET_APPROVED,
  SET_ASC,
  TOGGLE_DOCUMENT_PICTURE_PANEL,
  CLOSE_DOCUMENT_PICTURE_PANEL
} as MutationTree<State>;
