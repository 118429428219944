import { log } from '@/helpers/ConsoleLogHelper';
import router from '@/router';
import store from '@/store/store';

export const handleError = async (error: any) => {
  log(`${error}`);
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    let message = 'Server error';
    if (error.response.data && error.response.data.status && error.response.data.status.error) {
      message = error.response.data.status.error.message;
    }
    store.commit('connectivity/SET_ERROR', message, { root: true });

    if (error.response.status === 403) {
      await router.push({ name: 'Logout' });
    }
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    store.commit('connectivity/SET_IS_ONLINE', false, { root: true });
  } else {
    // Something happened in setting up the request that triggered an Error
    store.commit('connectivity/SET_ERROR', error.message, { root: true });
  }
};
