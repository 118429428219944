import Document from '@/models/Document/Document';
import Product from '@/models/Product/Product';
import { orderBy, pullAllBy, uniqBy } from 'lodash';
import { GetterTree } from 'vuex';
import { State } from './state';
const all = (state: State) => (): Product[] | null => {
  const products = pullAllBy(orderBy(uniqBy(state.products, 'title'), ['title'], ['asc']), [{ title: '' }], 'title');
  return products;
};
const byProjectId = (state: State, getters: any, rootState: any, rootGetters: any) => (
  projectId: string
): Product[] | null => {
  let products: Product[] = [];
  const productByProjectId = state.productsByProject.get(projectId);
  if (productByProjectId) {
    products = productByProjectId;
  } else {
    const documents = rootGetters['documents/byProjectId'](projectId);
    const productTitles = documents.map((document: Document) => document.product);
    products = state.products.filter((product: Product) => {
      return productTitles.indexOf(product.title) > -1;
    });
  }
  return pullAllBy(orderBy(uniqBy(products, 'title'), ['title'], ['asc']), [{ title: '' }], 'title');
};
export default {
  all,
  byProjectId
} as GetterTree<State, any>;
