import ChartOfAccount from '@/models/ChartOfAccount/ChartOfAccount';
import Vue from 'vue';
import { MutationTree } from 'vuex';
import { State } from './state';

const SET_ALL = (state: State, coas: ChartOfAccount[]) => {
  Vue.set(state, 'chartOfAccounts', coas);
};

const NOTIFY_NEWDATA = (state: State, isNewData: boolean) => {
  Vue.set(state, 'isNewData', isNewData);
};

const SET_ISINSYNC = (state: State, isInSync: boolean) => {
  Vue.set(state, 'isInSync', isInSync);
};

export default {
  SET_ALL,
  NOTIFY_NEWDATA,
  SET_ISINSYNC
} as MutationTree<State>;
