import { Module, Plugin } from 'vuex';
import Actions from './actions';
import Getters from './getters';
import Mutations from './mutations';
import { State } from './state';

export class Documents implements Module<State, any> {
  public namespaced: boolean = true;

  public state: State;
  public mutations = Mutations;
  public getters = Getters;
  public actions = Actions;
  public plugins: Array<Plugin<State>> = [];

  constructor(plugins?: Array<Plugin<State>>) {
    this.state = new State();
    if (plugins) {
      this.plugins = plugins;
    }
  }
}
