import localForageServices from '@/api/localForageService';
import LocalStoreNames from '@/models/LocalStoreNames';
import StoreNames from '@/models/StoreNames';

export const deleteDB = () => {
  const stores: string[] = [];
  stores.push(...Object.keys(StoreNames));
  stores.push(...Object.keys(LocalStoreNames));
  return Promise.all(
    stores.map(async (name) => {
      const s = await localForageServices.getStore(name);
      await s.clear();
    })
  );
};
