import { difference } from '@/api/difference';
import HistoryItem from '@/models/HistoryItem';

import nanoid from 'nanoid';

function createHistoryItem(
  storeName: string,
  keyPath: string,
  key: string,
  object: any = null,
  base: any = null
): HistoryItem {
  let fields;
  let action = '';

  if (object && base) {
    action = 'UPDATE';
    fields = difference(object, base);
  } else if (object) {
    action = 'CREATE';
    fields = object;
  } else {
    action = 'DELETE';
  }

  return new HistoryItem(nanoid(), storeName, keyPath, key, action, fields, new Date().getTime());
}
export default {
  createHistoryItem
};
