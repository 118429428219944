








import Vue from 'vue';
import FullScreenTooltip from '@/components/Common/FullScreenTooltip/FullScreenTooltip.vue';
export default Vue.extend({
  name: 'HelpTooltip',
  components: {
    FullScreenTooltip
  },
  computed: {
    helpContent() {
      switch (this.$route.name) {
        case 'Documents':
          return () => import(`@/components/Document/List/ListHelpContent.vue`);
          break;
        case 'Document':
          return () => import(`@/components/Document/Form/FormHelpContent.vue`);
          break;
        default:
          return '';
      }
    }
  }
});
