import IMeasurement from './IMeasurement';

export default class Measurement {
  public static createFromModel(measurement: IMeasurement) {
    return new Measurement(measurement.default, measurement.title, measurement.value);
  }

  public readonly default: string;
  public readonly title: string;
  public readonly value: string;
  public constructor(d: string, title: string, value: string) {
    // if (!id || !projectId) {
    //   throw new Error('Malformed object');
    // }
    this.default = d;
    this.title = title;
    this.value = (typeof value === 'number')?Number(value).toString():value;
  }
}
