enum StoreNames {
  projects = 'projects',
  documents = 'documents',
  pictures = 'pictures',
  products = 'products',
  suppliers = 'suppliers',
  measurements = 'measurements',
  coas = 'coas',
  documentDrafts = 'documentDrafts'
}
export default StoreNames;
