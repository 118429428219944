import axios from 'axios';
import { handleError } from '../APIErrorHandler';
import { handleSuccess } from '../APISuccessHandler';
export const getSync = async (
  storeName: string,
  currentTimestamp: number,
  previousTimestamp: number,
  accessToken: string,
  apiURL: string,
  projectId?: string,
  page?: string
) => {
  const url =
    `${apiURL}sync?` +
    `timestamp=${previousTimestamp}` +
    `&store=${storeName}` +
    `&now=${currentTimestamp}` +
    `&project=${projectId ? projectId : ''}` +
    `&page=${page ? page : ''}`;
  try {
    const response = await axios.get(url, {
      headers: { 'Access-Token': accessToken }
    });
    return handleSuccess(response);
  } catch (error) {
    handleError(error);
  }
};
