import localForageServices from '@/api/localForageService';
import { getSync } from '@/api/Sync/getSync';
import { putSync } from '@/api/Sync/putSync';
import { log } from '@/helpers/ConsoleLogHelper';
import ChartOfAccount from '@/models/ChartOfAccount/ChartOfAccount';
import { mapModelToObject } from '@/models/Document/DocumentMapper';
import HistoryItem from '@/models/HistoryItem';
import LocalStoreNames from '@/models/LocalStoreNames';
import Measurement from '@/models/Measurement/Measurement';
import Picture from '@/models/Picture/Picture';
import Product from '@/models/Product/Product';
import Project from '@/models/Project/Project';
import StoreNames from '@/models/StoreNames';
import Supplier from '@/models/Supplier/Supplier';
import { chunk, flatten, isArray, some } from 'lodash';
import nanoid from 'nanoid';
import { ActionContext, ActionTree } from 'vuex';
import { State } from './state';

const perProjectStoreNames = [
  StoreNames.documents.toString(),
  StoreNames.documentDrafts.toString(),
  StoreNames.pictures.toString(),
  StoreNames.coas.toString()
];
// Frequence at wich the app will automatically fetch backed.
const PROGRAMMED_FULL_SYNC_INTERVAL = 0//1000 * 60 * 2; // 2 minutes
// Minimum amount of time to wait before fetching backend again.
const MIN_REFRESH_INTERVAL = 2 * 1000; // 2 seconds

const PUT_HISTORYITEM_CHUNK_SIZE = 100;

let programedFullServerSyncId: any;

const save = async (store: ActionContext<State, any>, historyItem: HistoryItem) => {
  const isReady = store.rootGetters['connectivity/isReady']();
  let response;
  if (isReady) {
    const state = store.rootState as any;
    response = await putSync(historyItem, state.userProfile.userProfile.accessToken, process.env.VUE_APP_API_URL);
  }
  if (!isReady || !response) {
    const historyItemsStorage = await localForageServices.getStore(LocalStoreNames.historyItems);
    historyItemsStorage.setItem(historyItem.id, historyItem);
  }
};

const programFullServerSync = async (store: ActionContext<State, any>) => {
  if (PROGRAMMED_FULL_SYNC_INTERVAL) {
    programedFullServerSyncId = setInterval(() => {
      executeFullServerSync(store);
    }, PROGRAMMED_FULL_SYNC_INTERVAL);
  }
};

const cancelProgramedFullServerSync = async (store: ActionContext<State, any>) => {
  clearInterval(programedFullServerSyncId);
};

const executeFullServerSync = async (store: ActionContext<State, any>) => {
  await pushAllToServer(store);

  await store.dispatch('projects/getAll', true, {
    root: true
  });

  const storeNames = Object.keys(StoreNames).filter((s: string) => s !== StoreNames.projects); //remove projects
  await getFromServerByStoreNames(store, storeNames);
};

const pushAllToServer = async (store: ActionContext<State, any>) => {
  const historyItemsStorage = await localForageServices.getStore(LocalStoreNames.historyItems);
  const historyItems = await historyItemsStorage.getItems();
  const historyItemValues = Object.entries(historyItems).map((item) => item[1] as HistoryItem);

  const batches = chunk(historyItemValues, PUT_HISTORYITEM_CHUNK_SIZE);

  return batches.map(async (batch: HistoryItem[]) => {
    return pushBatch(store, batch);
  });
};

//#region private
const pushBatch = async (store: ActionContext<State, any>, batch: HistoryItem[]) => {
  if (store.rootGetters['connectivity/isReady']()) {
    log(`Pushing ${batch.length} change(s).`);
    const response = await putSync(
      batch,
      store.rootState.userProfile.userProfile.accessToken,
      process.env.VUE_APP_API_URL
    );
    if (response) {
      return batch.map(async (item: HistoryItem) => {
        const historyItemsStorage = await localForageServices.getStore(LocalStoreNames.historyItems);
        return await historyItemsStorage.removeItem(item.id);
      });
    }
  }
};

const handleNewProjects = async (
  store: ActionContext<State, any>,
  storeName: string,
  projects: Project[]
): Promise<boolean[][]> => {
  const newProjects = projects.filter((p: Project) => p.isNew);
  log(`${storeName} got ${newProjects.length} new project(s) to sync`);
  return await Promise.all(
    newProjects.map(async (project: Project) => {
      return await getFromServerByStoreNameAndProject(store, storeName, project);
    })
  );
};

const getFromServerByStoreNames = async (store: ActionContext<State, any>, storeNames: string[]) => {
  return Promise.all(
    storeNames.map(async (storeName: string) => {
      try{
        log(`${storeName} --- SYNC STARTED ---`);

        if (store.rootState[storeName].isInSync === true) {
          log(`${storeName}: skipped, already in Sync.`);
          return;
        }
        const timestamps = await getTimeStamps(storeName);
        if (timestamps.currentTimestamp - MIN_REFRESH_INTERVAL < parseInt(timestamps.previousTimestamp)) {
          log(`${storeName}: skipped, synched less than ${MIN_REFRESH_INTERVAL / 1000} seconds ago.`);
          return;
        }

        await store.dispatch(storeName + '/setIsInSync', true, { root: true });

        let newResults: boolean[] = [];
        if (perProjectStoreNames.includes(storeName)) {
          if (timestamps.previousTimestamp > 0) {
            const projects = store.rootState.projects.projects;
            newResults = flatten(await handleNewProjects(store, storeName, projects));
          }
        }
        const results = await getFromServerByStoreName(store, storeName);

        await notifyIfChanges(store, storeName, [...newResults, ...results]);
        await store.dispatch(storeName + '/setIsInSync', false, { root: true });
        log(`${storeName} --- SYNC ENDED ---`);
      }
      catch(e){
        log(`${storeName} --- SYNC ENDED WITH ERRORS ---`);
      }
    })
  );
};

const getFromServerByStoreName = async (store: ActionContext<State, any>, storeName: string): Promise<boolean[]> => {
  let results: boolean[] = [];
  let isComplete = false;

  while (!isComplete) {
    //Load initial timestamp
    let timestamps = await getTimeStamps(storeName);

    log(
      `${storeName} get change(s) from  ${new Date(
        timestamps.previousTimestamp * 1000
      ).toLocaleString()} up to : ${new Date(timestamps.currentTimestamp).toLocaleString()}`
    );

    const data = await getFromServer(store, storeName, timestamps);
    if (!data) {
      log(`No ${storeName} data received from server!`);
      return results;
    }

    const { response, status } = data;
    const currentResults = await saveToStorage(store, storeName, response);
    results = [...results, ...currentResults];

    setNewTimeStamp(storeName, status);

    log(
      `${storeName} got ${response.length} change(s) untill ${new Date(
        status.sync.lastTimestamp * 1000
      ).toLocaleString()} page ${timestamps.page} `
    );
    isComplete = !!status.sync.complete;
/*
    //Reload timestamp for next while loop (page load from server).
    timestamps = {
      previousTimestamp: timestamps.currentTimestamp,
      currentTimestamp: status.sync.lastTimestamp,
      page: timestamps.page+1
    };
  */
  }
  return results;
};

const getFromServerByStoreNameAndProject = async (
  store: ActionContext<State, any>,
  storeName: string,
  project: Project
): Promise<boolean[]> => {
  let results: boolean[] = [];
  let isComplete = false;
  let previousTimestamp = 0;

  while (!isComplete) {
    const timestampsStorage = await localForageServices.getStore(LocalStoreNames.syncTimestamps);

    //Make sure DB store is ready, otherwise everything just hangs and no
    //no errors are thrown...
    await timestampsStorage.ready();

    const latestInDB = (await timestampsStorage.getItem(storeName)) as any;
    const upTo = latestInDB && latestInDB.latestTimestamp ? latestInDB.latestTimestamp : new Date().getTime();
    const timestamps = { previousTimestamp, currentTimestamp: upTo };

    const data = await getFromServer(store, storeName, timestamps, project.id);
    if (!data) {
      return results;
    }
    const { response, status } = data;
    const currentResults = await saveToStorage(store, storeName, response);
    results = [...results, ...currentResults];
    previousTimestamp = status.sync.lastTimestamp;
    log(
      `${storeName} ${project.projectName} got ${response.length} change(s) untill ${new Date(
        status.sync.lastTimestamp * 1000
      ).toLocaleString()} `
    );
    isComplete = !!status.sync.complete;
  }

  return results;
};

const getTimeStamps = async (storeName: string) => {
  const timestampsStorage = await localForageServices.getStore(LocalStoreNames.syncTimestamps);
  const timestamp = (await timestampsStorage.getItem(storeName)) as any;
  const previousTimestamp = timestamp ? timestamp.latestTimestamp : 0;
  const currentTimestamp = new Date().getTime();
  const page = timestamp ? timestamp.page : 0;
  return { previousTimestamp, currentTimestamp, page };
};

const setNewTimeStamp = async (storeName: string, status: any) => {
  const timestampsStorage = await localForageServices.getStore(LocalStoreNames.syncTimestamps);
  let existingTimeStamps = null;
  try{
    existingTimeStamps = (await timestampsStorage.getItem(storeName)) as any;
  }
  catch(error) {
    //Reloading window prevents bugs when accessing storage...
    setTimeout(function(){
      window.location.reload(false);
    },1500);
  }
  let page = existingTimeStamps ? existingTimeStamps.page : 0;
  if (existingTimeStamps && existingTimeStamps.latestTimestamp === status.sync.lastTimestamp) {
    page++;
  } else {
    page = 0;
  }
  const lastTimeStamp = status.sync.lastTimestamp;
  const diff = status.error.timestamp - new Date().getTime() / 1000;
  const newTimestamp = {
      'id': storeName,
      'store': storeName,
      'latestTimestamp': lastTimeStamp,
      'diff': diff,
      'page': page
  };
  timestampsStorage.setItem(storeName, newTimestamp);
  return newTimestamp;
};

const getFromServer = async (
  store: ActionContext<State, any>,
  storeName: string,
  timestamps: any,
  projectId?: string
) => {
  if (store.rootGetters['connectivity/isReady']()) {
    let { previousTimestamp, currentTimestamp, page } = timestamps;
    const state = store.rootState as any;
    return await getSync(
      storeName,
      currentTimestamp,
      previousTimestamp,
      state.userProfile.userProfile.accessToken,
      process.env.VUE_APP_API_URL,
      projectId,
      page
    );
  }
};

const saveToStorage = async (
  store: ActionContext<State, any>,
  storeName: string,
  historyItems: []
): Promise<boolean[]> => {
  const db = await localForageServices.getStore(storeName);

  const results = historyItems.map(async (historyItem: any) => {
    try {
      const { object, metadata } = historyItem;
      let item;

      if (!object || !metadata) {
        throw new Error('Malformed historyItem');
      }

      switch (storeName) {
        case StoreNames.documents:
          if (metadata.deleted > 0) {
            handleDeletedObject(object, 'id', historyItem, db);
          } else {
            item = mapModelToObject(object);
            if (item) {
              db.setItem(item.id, item);
            }
          }
          return true;
        case StoreNames.pictures:
          if (metadata.deleted > 0) {
            handleDeletedObject(object, 'id', historyItem, db);
          } else {
            item = Picture.createFromModel(object) as Picture;
            db.setItem(item.id, item);
          }
          return true;
        case StoreNames.products:
          if (metadata.deleted > 0) {
            handleDeletedObject(object, 'value', historyItem, db);
          } else {
            item = Product.createFromModel(object) as Product;
            db.setItem(item.value, item);
          }
          return true;
        case StoreNames.suppliers:
          if (metadata.deleted > 0) {
            handleDeletedObject(object, 'value', historyItem, db);
          } else {
            item = Supplier.createFromModel(object) as Supplier;
            db.setItem(item.value, item);
          }
          return true;
        case StoreNames.measurements:
          if (metadata.deleted > 0) {
            handleDeletedObject(object, 'value', historyItem, db);
          } else {
            item = Measurement.createFromModel(object) as Measurement;
            db.setItem(item.value, item);
          }
          return true;
        case StoreNames.coas:
          if (metadata.deleted > 0) {
            handleDeletedObject(object, ['code', 'project'], historyItem, db);
          } else {
            item = ChartOfAccount.createFromModel(object) as ChartOfAccount;
            db.setItem(item.code + item.project, item);
          }
          return true;
        case StoreNames.documentDrafts:
          if (metadata.deleted > 0) {
            handleDeletedObject(object, 'id', historyItem, db);
          } else {
            item = mapModelToObject(historyItem.object);
            if (item) {
              db.setItem(item.id, item);
            }
          }
          return true;
        default:
          log(storeName + ' is not mapped.');
          return false;
      }
    } catch (error) {
      log(storeName+': Error saving object store: '+error);
      log(historyItem.object);
      return false;
    }
  });
  return Promise.all(results);
};
const handleDeletedObject = async (object: any, key: string | string[], historyItem: any, db: LocalForage) => {
  let keyContent: string = '';
  let noKey: boolean = false;
  if (isArray(key)) {
    key.forEach((k: string) => {
      if (!object[k]) {
        noKey = true;
      } else {
        keyContent += object[k];
      }
    });
  } else {
    if (!object[key]) {
      noKey = true;
    } else {
        keyContent = object[key];
    }
  }
  if (noKey) {
    log("No key to delete this object: ");
    log(historyItem.object);
  } else {
    if (typeof keyContent === 'number') {
      keyContent = Number(keyContent).toString();
    }
    db.removeItem(keyContent);
  }
};
const notifyIfChanges = async (store: ActionContext<State, any>, storeName: string, results: any) => {
  const result = some(results, (r) => r === true);
  if (result) {
    await store.dispatch(storeName + '/notifyNewData', result, { root: true });
  }
};
//#endregion
export default {
  programFullServerSync,
  cancelProgramedFullServerSync,
  executeFullServerSync,
  getFromServerByStoreNames,
  pushAllToServer,
  save
} as ActionTree<State, any>;
