import IPicture from './IPicture';
export default class Picture {
  public static createFromModel(picture: IPicture) {
    return new Picture(
      picture.id,
      picture.projectId,
      picture.createdBy,
      picture.imgSrc,
      picture.imgEnlargedSize,
      picture.imgLargeSize,
      picture.imgMediumSize,
      picture.imgSmallSize,
      picture.imgTinySize
    );
  }

  public readonly id: string;
  public readonly projectId: string;
  public readonly createdBy: string;
  public readonly imgSrc: string;

  public readonly imgEnlargedSize: string;
  public readonly imgLargeSize: string;
  public readonly imgMediumSize: string;
  public readonly imgSmallSize: string;
  public readonly imgTinySize: string;

  public constructor(
    id: string,
    projectId: string,
    createdBy: string,
    imgSrc: string,
    imgEnlargedSize?: string,
    imgLargeSize?: string,
    imgMediumSize?: string,
    imgSmallSize?: string,
    imgTinySize?: string
  ) {
    if (!id && !imgSrc) {
      throw new Error('Malformed object');
    }

    this.id = id;
    this.projectId = projectId;
    this.createdBy = createdBy;
    this.imgSrc = imgSrc || '';

    this.imgEnlargedSize = imgEnlargedSize || '';
    this.imgLargeSize = imgLargeSize || '';
    this.imgMediumSize = imgMediumSize || '';
    this.imgSmallSize = imgSmallSize || '';
    this.imgTinySize = imgTinySize || '';
  }
}
