import Document from '@/models/Document/Document';
import Supplier from '@/models/Supplier/Supplier';
import { orderBy, pullAllBy, uniqBy } from 'lodash';
import { GetterTree } from 'vuex';
import { State } from './state';

const all = (state: State) => (): Supplier[] | null => {
  return pullAllBy(orderBy(uniqBy(state.suppliers, 'title'), ['title'], ['asc']), [{ title: '' }], 'title');
};

const byProjectId = (state: State, getters: any, rootState: any, rootGetters: any) => (
  projectId: string
): Supplier[] | null => {
  let suppliers: Supplier[] = [];
  const suppliersByProjectId = state.suppliersByProject.get(""+projectId);
  if (suppliersByProjectId) {
    suppliers = suppliersByProjectId;
  } else {
    const documents = rootGetters['documents/byProjectId'](""+projectId);
    const supplierTitles = documents.map((document: Document) => document.supplier);
    suppliers = state.suppliers.filter((supplier: Supplier) => {
      return supplierTitles.indexOf(""+supplier.title) > -1;
    });
  }
  return pullAllBy(orderBy(uniqBy(suppliers, 'title'), ['title'], ['asc']), [{ title: '' }], 'title');
};

export default {
  all,
  byProjectId
} as GetterTree<State, any>;
