import Document from '@/models/Document/Document';
import DocumentVersion from './DocumentVersion';
import IDocumentModel from './IDocumentModel';
import IDocumentVersionModel from './IDocumentVersionModel';

export const mapModelToObject = (model: IDocumentModel): Document => {
  const object = new Document(
    model.id,
    model.projectId,
    model.createdBy,
    model.userName,
    model.masterId,
    model.toSend,
    model.date ? parseDate(model.date) : undefined,
    model.comment,
    model.ticket,
    model.supplier,
    model.product,
    model.chartOfAccount,
    model.units,
    model.perUnit,
    model.verified,
    model.verifiedComment,
    model.redFlag,
    model.redFlagComment,
    model.isNew,
    model.measurement,
    model.serviceDate ? parseDate(model.serviceDate) : undefined,
    model.history && !model.masterId //Document without masterId ARE masters. Only masters should have versions.
      ? model.history.map((version: IDocumentVersionModel) => DocumentVersion.createFromModel(version))
      : [],
    model.pictureIds,
    model.approved,
    model.approvedComment,
    model.tax,
    model.tip,
    model.total,
    model.amount,
    model.deposit,
    model.whoPays,
    model.invoiceNumber,
    model.poNumber
  );
  return object;
};

const parseDate = (date: any) => {
  if (typeof date === 'string') {
    if (date.length === 10)
      //we've got a unix timestamp in seconds, conver to number with milliseconds{
      return parseInt(date) * 1000;
  }
  return parseInt(date);
};
