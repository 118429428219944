import DocumentFilter from '@/models//Document/DocumentFilter';
import DocumentLayouts from '../DocumentLayouts';
import IFilter from '../IFilter';

export default class UserSettings {
  public readonly selectedProjectId: string;
  public readonly selectedFilter: DocumentFilter;
  public readonly filters: IFilter[];
  public readonly queryText: string;

  public readonly documentLayout: DocumentLayouts;

  public constructor(
    selectedProjectId: string,
    selectedFilter: DocumentFilter,
    queryText?: string,
    filters?: IFilter[],
    documentLayout?: DocumentLayouts
  ) {
    this.selectedProjectId = selectedProjectId;
    this.selectedFilter = selectedFilter || DocumentFilter.CURRENT;
    this.filters = filters || [];
    this.queryText = queryText || '';
    this.documentLayout = documentLayout || DocumentLayouts.split;
  }
}
