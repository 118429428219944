import Supplier from '@/models/Supplier/Supplier';
import Vue from 'vue';
import { MutationTree } from 'vuex';
import { State } from './state';

const SET_ALL = (state: State, suppliers: Supplier[]) => {
  Vue.set(state, 'suppliers', suppliers);
};

const SET_BYPROJECTID = (state: State, payload: { projectId: string; suppliers: Supplier[] }) => {
  const { projectId, suppliers } = payload;
  state.suppliersByProject.set(""+projectId, suppliers);
};

const NOTIFY_NEWDATA = (state: State, isNewData: boolean) => {
  Vue.set(state, 'isNewData', isNewData);
};

const SET_ISINSYNC = (state: State, isInSync: boolean) => {
  Vue.set(state, 'isInSync', isInSync);
};

export default {
  SET_ALL,
  NOTIFY_NEWDATA,
  SET_ISINSYNC,
  SET_BYPROJECTID
} as MutationTree<State>;
