import Document from '@/models/Document/Document';
import Picture from '@/models/Picture/Picture';
import { pullAllBy } from 'lodash';
import Vue from 'vue';
import { MutationTree } from 'vuex';
import { State } from './state';

const SET_ALL = (state: State, documents: Document[]) => {
  Vue.set(state, 'documents', documents);
};

const ADD = (state: State, document: Document) => {
  if (document) {
    state.documents.push(document);
  }
};

const ADD_PICTURE = (state: State, payload: { document: Document; picture: Picture }) => {
  const { document, picture } = payload;
  if (document && picture) {
    document.pictureIds.push(picture.id);
    state.documents = [...state.documents.filter((p: Document) => p.id !== document.id), document];
  }
};

const REMOVE_PICTURE = (state: State, payload: { document: Document; picture: Picture }) => {
  const { document, picture } = payload;
  if (document && picture) {
    const index = document.pictureIds.findIndex((pid: string) => pid === picture.id);
    if (index > -1) {
      document.pictureIds.splice(index, 1);
    }
    state.documents = [...state.documents.filter((p: Document) => p.id !== document.id), document];
  }
};

const EDIT = (state: State, document: Document) => {
  if (document) {
    state.documents = [...state.documents.filter((p: Document) => p.id !== document.id), document];
  }
};

const EDIT_BATCH = (state: State, documents: Document[]) => {
  pullAllBy(state.documents, documents, 'id');
  state.documents.push(...documents);
};

const DELETE = (state: State, id: string) => {
  const index = state.documents.findIndex((p: Document) => p.id === id);
  if (index > -1) {
    state.documents.splice(index, 1);
  }
};
const NOTIFY_NEWDATA = (state: State, isNewData: boolean) => {
  Vue.set(state, 'isNewData', isNewData);
};

const SET_ISINSYNC = (state: State, isInSync: boolean) => {
  Vue.set(state, 'isInSync', isInSync);
};

export default {
  SET_ALL,
  ADD,
  ADD_PICTURE,
  REMOVE_PICTURE,
  EDIT,
  EDIT_BATCH,
  DELETE,
  NOTIFY_NEWDATA,
  SET_ISINSYNC
} as MutationTree<State>;
