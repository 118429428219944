import DocumentFilter from '@/models/Document/DocumentFilter';
import FilterNames from '@/models/FilterNames';
import IFilter from '@/models/IFilter';
import UserProfile from '@/models/User/UserProfile';
import UserSettings from '@/models/User/UserSettings';

interface IUserProfileState {
  isAuthed: boolean;
  userProfile?: UserProfile;
  settings: UserSettings;
}

export const getInitialState = (): IUserProfileState => {
  return {
    isAuthed: false,
    userProfile: undefined,
    settings: new UserSettings(
      '',
      DocumentFilter.CURRENT,
      '',
      Object.values(FilterNames).map((s: string) => {
        return { name: s, isApplied: false, parameters: {} } as IFilter;
      })
    )
  };
};

const initialState = getInitialState();
export class State implements IUserProfileState {
  public isAuthed: boolean = initialState.isAuthed;
  public userProfile?: UserProfile = initialState.userProfile;
  public settings: UserSettings = initialState.settings;
}
